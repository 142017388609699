<template>
  <q-page class="locationPage">
    <div class="page-title hide-in-mobile" v-if="!isMobile">
      <div class="page-title--left">
        <h1>
          <template v-if="!loading && location">
            {{ location.name }}
          </template>
          <q-skeleton v-else type="rect" width="200px" />
        </h1>
        <q-breadcrumbs v-if="!loading && location">
          <q-breadcrumbs-el
            :label="currentLocationId ? 'Home' : 'Find a Store'"
            :to="{ name: currentLocationId ? 'Home' : 'FindStore' }"
          />
          <q-breadcrumbs-el :label="location.name" />
        </q-breadcrumbs>
        <q-skeleton v-else type="text" width="100px" class="q-mt-sm" />
      </div>
      <div class="page-title--right">
        <q-btn
          unelevated
          no-caps
          rounded
          color="secondaryOnBody"
          label="Find a Store"
          class="q-px-md"
          :to="{ name: 'FindStore' }"
          v-show="!loading"
        />
        <q-skeleton v-show="loading" type="QChip" width="180" height="30px" />
      </div>
    </div>
    <div class="page-content">
      <!-- <div
        class="map-container"
        ref="mapPlaceCardRef"
        v-if="location"
        style="display:none;"
      >
        <div
          v-if="location && location.placeID && !loading"
          class="placeDiv"
          :style="[
            $q.screen.width > 1023 ? { left: '35px' } : { left: '15px' },
          ]"
        >
          <div class="placecard__container">
            <div class="placecard__left">
              <p class="placecard__business-name">Store Address</p>
              <p
                class="placecard__info"
                v-html="$options.filters.formatAddress(location.address)"
              >
              </p>
              <a
                class="placecard__view-large"
                target="_blank"
                :href="
                  `http://maps.google.com/maps?daddr=${
                    location.address
                      ? location.address.geoLocation
                      : ''
                  }`
                "
                id="A_41"
              >
                View larger map
              </a>
            </div>

            <div class="placecard__right">
              <q-btn
                unelevated
                no-caps
                class="placecard__direction-link"
                @click="redirectToMap()"
                id="A_9"
              >
                <div class="placecard__direction-icon"></div>
                Directions
              </q-btn>
            </div>
          </div>
        </div>
      </div> -->
      <div class="locationGrid">
        <div
          v-if="location?.placeID && !loading"
          class="locationMap"
          style="overflow: hidden"
        >
          <q-no-ssr>
            <iframe
              :src="mapUrl"
              width="100%"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              class="iframe"
              :style="[{ border: 0 }]"
              v-on:load="loadPlaceCard"
            />
          </q-no-ssr>
        </div>
        <div v-else-if="loading" class="locationMap">
          <q-skeleton type="rect" width="100%" class="iframe" />
        </div>
        <div
          :class="[
            'locationDetail',
            location?.placeID || loading ? '' : 'full-width',
          ]"
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <template v-if="!loading && location">
                <h2>
                  {{ location.name }}
                  <span
                    v-if="
                      location.locationId === currentLocationId &&
                      selectedFulfillmentCodeName != 'sd'
                    "
                  >
                    My Store
                  </span>
                </h2>
                <p v-html="$options.filters.formatAddress(location.address)">
                </p>
                <ul v-if="location.address.email || location.address.phone">
                  <li v-if="location.address.email">
                    <span>Email:</span>
                    <a :href="`mailto:${location.address.email}`">
                      {{ location.address.email }}
                    </a>
                  </li>
                  <li v-if="location.address.phone">
                    <span>Phone:</span>
                    <a :href="`tel:${location.address.phone}`">
                      <strong>{{ location.address.phone }} </strong>
                    </a>
                  </li>
                </ul>
                <!-- <p v-if="location.showSetPreffered">
                  <q-btn
                    unelevated
                    no-caps
                    rounded
                    outline
                    color="tertiary"
                    @click="
                      $store.dispatch('location/setAsMyStore', location)
                    "
                    label="Set as my store"
                    class="q-mr-md"
                    v-if="location.locationId !== currentLocationId"
                  />
                  <q-btn
                    v-else
                    unelevated
                    no-caps
                    rounded
                    color="tertiary"
                    label="Continue Shopping"
                    class="q-px-md"
                    :to="{ name: 'Home' }"
                  />
                </p> -->
              </template>
              <template v-else>
                <h2>
                  <q-skeleton type="rect" width="200px" />
                </h2>
                <p>
                  <q-skeleton type="text" width="80%" /><q-skeleton
                    type="text"
                    width="100px"
                  />
                </p>
                <ul>
                  <li>
                    <span><q-skeleton type="text" width="50px" /></span>
                    <q-skeleton type="text" width="30%" />
                  </li>
                  <li>
                    <span><q-skeleton type="text" width="50px" /></span>
                    <q-skeleton type="text" width="30%" />
                  </li>
                </ul>
              </template>

              <div class="locationBox">
                <h3>Store Hours</h3>
                <div class="locationBoxContent">
                  <div class="storeHours">
                    <template v-if="!loading && location">
                      <div class="storeHoursLeft" v-if="location.openingTime">
                        <p
                          v-for="(item, key, index) in location.openingTime"
                          :key="`${key}-${index}`"
                        >
                          <span class="text-capitalize">{{ key }}</span>
                          {{ item }}
                        </p>

                        <!-- <p class="closed">
                        <span>Mon:</span> 8:00 am to 10:00 pm
                      </p>  -->
                      </div>
                      <div class="storeHoursRight">
                        <!-- <p><span>Mon:</span> 8:00 am to 10:00 pm</p>
                      <p><span>Mon:</span> 8:00 am to 10:00 pm</p> -->
                        <div class="holiday" v-if="holidays && holidays.length">
                          <div
                            v-for="(holiday, index) in holidays"
                            :key="index"
                          >
                            {{ holiday.name }} <strong>Closed</strong></div
                          >
                        </div>
                      </div>
                    </template>
                    <div class="storeHoursLeft" v-else>
                      <p v-for="n in 7" :key="n">
                        <span><q-skeleton type="text" width="40px" /></span>
                        <q-skeleton type="text" width="30%" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <p class="storePicture">
                <q-no-ssr>
                  <q-img
                    v-if="!loading && location"
                    spinner-color="primary"
                    :src="image"
                    :ratio="1"
                    contain
                  />
                  <!-- <q-skeleton v-else type="rect" width="100%" height="200px" /> -->
                </q-no-ssr>
              </p>

              <div class="col-12 col-md-6">
                <div class="locationBox">
                  <template v-if="!loading && location">
                    <h3>Available Fulfillments options</h3>
                    <div class="locationBoxContent">
                      <ul class="storeAvailability">
                        <li
                          v-for="(fulFillment, index) in getFulfillments"
                          :key="`${fulFillment.fulfillmentMethodID}-${index}`"
                          :class="[
                            !fulFillment.available ? 'disabled' : '',
                            fulFillment.configuration.isComingSoon
                              ? 'comingsoon'
                              : '',
                          ]"
                          v-show="fulFillment.available"
                        >
                          <!-- ToDo: v-show Temporary -->
                          <SvgIcon :icon="fulFillment.codeName" />
                          <q-btn
                            v-if="fulFillment.configuration.isComingSoon"
                            unelevated
                            no-caps
                            label="Coming Soon!"
                            padding="0"
                            class="text-underline text-secondaryOnBody no-hover"
                          />
                          <q-tooltip
                            max-width="180px"
                            content-class="bg-dark"
                            :offset="[0, 4]"
                          >
                            {{ fulFillment.displayName }}
                          </q-tooltip>
                        </li>
                      </ul>
                    </div>
                  </template>
                  <template v-else>
                    <h3
                      ><q-skeleton type="rect" width="200px" height="15px"
                    /></h3>
                    <div class="locationBoxContent">
                      <div class="storeHours"> </div>
                    </div>
                  </template>
                </div>
                <div class="locationBox">
                  <template v-if="!loading && location?.services">
                    <h3 v-if="allServicesAvailable">Store Services</h3>
                    <div class="locationBoxContent" v-if="allServicesAvailable">
                      <ul class="storeAvailability">
                        <li
                          v-for="(service, index) in location.services"
                          :key="`${service.serviceID}-${index}`"
                          :class="{ disabled: !service.available }"
                          v-show="service.available"
                        >
                          <template v-if="service.available">
                            <SvgIcon :icon="service.title" />
                            <q-tooltip
                              max-width="180px"
                              content-class="bg-dark"
                              :offset="[0, 4]"
                            >
                              {{ service.title }}
                            </q-tooltip>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </template>
                  <template v-else>
                    <h3
                      ><q-skeleton type="rect" width="200px" height="15px"
                    /></h3>
                    <div class="locationBoxContent">
                      <div class="row">
                        <div v-for="n in 4" :key="n" class="q-mr-sm"
                          ><q-skeleton
                            type="circle"
                            width="40px"
                            height="40px"
                            class="q-mb-sm"
                        /></div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="locationNearStore" v-if="!loading && location">
        <h4>
          <SvgIcon size="1rem" icon="store" class="q-mr-sm" /> Nearby stores
          <span class="storeSort q-ml-sm">
            <span>Miles: </span>
            <q-select
              dense
              borderless
              @input="this.dispatchSearch"
              v-model="WithinMiles"
              :options="milesOptions"
              option-value="val"
              option-label="label"
              emit-value
              map-options
              class="q-ml-xs"
            />
          </span>
        </h4>
        <q-infinite-scroll
          v-if="activated"
          @load="fetchNext"
          :offset="1500"
          ref="infiniteScroll"
        >
          <LocationList
            :locations="nearByLocations"
            :searchedFulfillment="{
              codeName: 'sp',
            }"
          />
          <template v-slot:loading>
            <LocationSkeleton v-show="fetching" />
            <LoadMoreSpinner v-show="!fetching" />
          </template>
        </q-infinite-scroll>

        <EmptyComponent
          v-if="!fetching && nearByLocations.length == 0"
          image="images/empty-location.png"
        >
          <p>No Location Found.</p>
        </EmptyComponent>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PageLocation',
  preFetch({ store, currentRoute, redirect }) {
    let { locationId } = currentRoute.params,
      promise = store
        .dispatch('locationDetail/fetchLocation', {
          locationId: locationId,
          isLocationDetailPage: true,
        })
        .then((result) => {
          if (!result)
            redirect({
              name: 'Home',
            })
        })

    if (process.env.SERVER) return promise
  },
  components: {
    LocationList: () => import('components/location/LocationList'),
    LocationSkeleton: () => import('components/location/LocationSkeleton'),
  },
  meta() {
    return {
      title: this.title,
      ...this.commonMeta,
      meta: {
        title: {
          name: 'title',
          content: this.metaTitle,
        },
        description: {
          name: 'description',
          content: this.metaTitle,
        },
        ogTitle: {
          property: 'og:title',
          content: this.title,
        },
        ogDescription: {
          property: 'og:description',
          content: this.title,
        },
        ogUrl: {
          property: 'og:url',
          content: this.currentRoutePath,
        },
      },
      link: {
        canonical: {
          rel: 'canonical',
          href: this.currentRoutePath,
        },
      },
    }
  },
  data() {
    return {
      activated: false,
      fetching: false,
      nearByLocations: [],
      WithinMiles: '5',
    }
  },
  computed: {
    ...mapGetters('locationDetail', ['location', 'milesOptions', 'loading']),
    title() {
      return this.location?.name || ''
    },
    allServicesAvailable() {
      let avl = false
      if (this.location) {
        for (var ser in this.location.services) {
          if (this.location.services[ser].available == true) {
            avl = true
            break
          }
        }
      }

      return avl
    },
    image() {
      if (
        this.location.image?.constructor === Array &&
        this.location.image.length &&
        this.location.image[0].imageURL
      ) {
        return this.location.image[0].imageURL
      }
      return 'https://via.placeholder.com/600x300.png?text=No+Image'
    },
    mapUrl() {
      if (this.location?.placeID) {
        return `https://www.google.com/maps/embed/v1/place?q=place_id:${this.location.placeID}&key=AIzaSyCA4c3-kluYCrSg3UosUF6Xf--nHsfzhKY`
      }
      return ''
    },
    holidays() {
      return this.location.holidays.filter((holiday) => holiday.name)
    },
    getFulfillments() {
      return this.location.fulFillments.filter(
        (fulFillment) => fulFillment.available == true
      )
    },
    metaTitle() {
      if (this.businessName) return `${this.businessName} - ${this.title}`
      else return this.title
    },
  },
  activated() {
    this.activated = true
  },
  deactivated() {
    this.activated = false
    this.nearByLocations = []
  },
  methods: {
    async fetchNext(index, done) {
      if (!this.location && !this.location.address) {
        return done(true)
      }

      this.fetching = index == 1

      let hasNext = false

      let data = await this.$store.dispatch(
        'locationDetail/getNearBYLocation',
        {
          geoLocation: this.location.address.geoLocation,
          WithinMiles: this.WithinMiles,
          isStorePickup: true,
          isScheduledDelivery: false,
          isStandardDelivery: false,
          Page: index,
          pageSize: 6,
        }
      )
      if (data && data.data && data.data.items) {
        let { items } = data.data
        hasNext = data.data.hasNext
        this.nearByLocations = this.nearByLocations.concat(items)
      }

      this.fetching = false
      done(!hasNext)
    },
    dispatchSearch() {
      if (this.$refs.infiniteScroll) {
        this.nearByLocations = []
        this.$refs.infiniteScroll.reset()
        this.$refs.infiniteScroll.resume()
        this.$refs.infiniteScroll.trigger()
      }
    },
    loadPlaceCard() {
      if (this.$refs.mapPlaceCardRef) {
        setTimeout(() => {
          let placeCard = this.$refs.mapPlaceCardRef
          placeCard.style.display = ''
        }, 500)
      }
    },
    redirectToMap() {
      if (this.isMobile) {
        window.open(
          `google.navigation:q=${
            this.location.address ? this.location.address.geoLocation : ''
          }&mode=d`,
          '_system'
        )
      } else {
        window.open(
          `http://maps.google.com/maps?daddr=${
            this.location.address ? this.location.address.geoLocation : ''
          }`
        )
      }
    },
  },
}
</script>
<style lang="scss">
/* map place card css start */
.placeDiv {
  z-index: 2;
  position: absolute;
}

.map-container {
  position: relative;
}

.placecard {
  &__container {
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    max-width: 330px;
    width: 100%;
    background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box
      border-box;
    border-radius: 2px 2px 2px 2px;
    font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
    margin: 10px;
    padding: 9px 4px 9px 11px;
    overflow: hidden;
  }

  &__left {
    float: left;
    width: 75%;
  }

  &__right {
    text-align: center;
    float: left;
    width: 25%;
  }

  &__business-name {
    cursor: default;
    height: 19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    perspective-origin: 100px 9.5px;
    transform-origin: 100px 9.5px;
    font: normal normal 500 normal 14px / normal Roboto, Arial;
    overflow: hidden;
    margin: 0;
  }

  &__info {
    color: rgb(91, 91, 91);
    cursor: default;
    // height: 32px;
    width: 200px;
    column-rule-color: rgb(91, 91, 91);
    perspective-origin: 100px 16px;
    transform-origin: 100px 16px;
    border: 0px none rgb(91, 91, 91);
    font: normal normal normal normal 12px / normal Roboto, Arial;
    margin: 6px 0px 0px;
    outline: rgb(91, 91, 91) none 0px;
  }

  &__direction-icon {
    background: rgba(0, 0, 0, 0)
      url('https://maps.gstatic.com/mapfiles/embed/images/entity11.png') repeat
      scroll 0px 0px / 70px 210px padding-box border-box;
    height: 22px;
    width: 22px;
    margin-right: auto;
    margin-left: auto;
  }

  &__direction-link {
    color: rgb(58, 132, 223);
    display: block;
    height: 43px;
    text-decoration: none;
    width: 54.7344px;
  }

  &__view-large {
    display: block;
    margin-top: 10px;
    color: rgb(58, 132, 223);
    text-decoration: none;
  }
}
/* map place card css end */
.locationPage {
  .page-title {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
      padding: 20px 40px;
    }
    @media (max-width: 1023px) {
      .page-title--left,
      .page-title--right {
        padding: 10px 20px;
      }
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 15px;
      line-height: normal;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      color: $primary;
      color: var(--q-color-primaryOnBody) !important;
      strong {
        font-weight: 600;
      }
      @media (min-width: 1440px) {
        font-size: 18px;
      }
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    .q-breadcrumbs {
      font-size: 14px;
      @media (min-width: 1681px) {
        font-size: 16px;
      }
    }
    @media (max-width: 599px) {
      .q-btn {
        width: 100%;
      }
    }
  }

  /* Content */
  .page-content {
    padding: 20px 0;
    // @media (min-width: 1024px) {
    //   padding: 40px 0;
    // }
  }
}
.locationGrid {
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .locationMap {
    width: 100%;
    padding: 0;
  }
  .locationDetail {
    width: 100%;
    .col-12 {
      padding: 0;
    }
  }
  @media (min-width: 1024px) {
    .locationMap,
    .locationDetail .col-12 {
      padding: 0 20px;
    }
  }
  @media (min-width: 1280px) {
    .locationMap {
      width: 33.33%;
    }
    .locationDetail {
      width: 66.66%;
    }
  }
  @media (max-width: 1023px) {
    .storePicture {
      margin-top: 20px;
      img {
        width: 100%;
      }
    }
  }
  .locationMap {
    .iframe {
      margin-bottom: 20px;
      height: 240px;
      border: 1px solid #cccccc;
      @media (min-width: 1280px) {
        height: 500px;
        margin-bottom: 0px;
      }
    }
  }
}
.locationDetail {
  font-size: 16px;
  color: #333333;
  @media (min-width: 1681px) {
    font-size: 18px;
  }
  h2 {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    font-size: 18px;
    line-height: normal;
    font-weight: bold;
    color: #314154;
    @media (min-width: 1280px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
    @media (min-width: 1681px) {
      font-size: 32px;
    }
    > span {
      margin: 0 10px;
      padding: 5px 15px;
      font-size: 14px;
      line-height: normal;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      color: #008a03;
      border: 1px solid currentColor;
      border-radius: 15px;
    }
  }
  p {
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    font-size: 14px;
    @media (min-width: 1681px) {
      font-size: 16px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 65px;
      font-size: 14px;
      min-height: 20px;
      @media (min-width: 1681px) {
        font-size: 16px;
      }
      span {
        position: absolute;
        left: 0;
        top: 0;
      }
      a {
        color: #0f141a;
      }
    }
  }
}
.locationBox {
  margin: 0;
  padding: 20px 0;
  h3 {
    margin: 0 0 15px;
    padding: 0 0 15px;
    font-size: 15px;
    line-height: normal;
    font-weight: bold;
    color: #314154;
    border-bottom: 1px solid rgba(136, 136, 136, 0.26);
    @media (min-width: 1681px) {
      font-size: 20px;
    }
  }
  .storeHours {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    @media (min-width: 1681px) {
      flex-direction: row;
      .storeHoursLeft,
      .storeHoursRight {
        width: 50%;
        padding-right: 20px;
      }
    }
    p {
      margin: 0;
      padding: 5px 0;
      padding-left: 55px;
      position: relative;
      font-size: 16px;
      color: #888888;
      @media (min-width: 1681px) {
        font-size: 18px;
      }
      span {
        position: absolute;
        left: 0;
        top: 4px;
      }
      &.closed {
        font-weight: bold;
        color: $tertiary;
      }
    }
    .holiday {
      position: relative;
      margin: 20px 0 0;
      padding: 8px 15px;
      font-size: 16px;
      line-height: 32px;
      color: $tertiary;
      border: 1px solid #cccccc;
      border-radius: 5px;
      strong {
        color: #ec7a7a;
      }
      &:before {
        content: '*';
        position: absolute;
        left: 15px;
        top: -9px;
        font-size: 35px;
        color: #888888;
      }
    }
  }
  .storeAvailability {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      padding: 0;
      margin: 0;
      margin-right: 10px;
      display: inline-block;
      vertical-align: top;
      i {
        display: block;
        margin: 0 auto;
        font-size: 1rem;
        width: 40px;
        height: 40px;
        line-height: 42px;
        text-align: center;
        color: $secondary;
        color: var(--q-color-secondaryOnBody) !important;
        border: 1px solid $secondary;
        border: 1px solid var(--q-color-secondaryOnBody) !important;
        border-radius: 50%;
        svg {
          width: 20px;
        }
      }
      .q-btn {
        .q-btn__wrapper,
        span {
          position: relative;
          top: auto;
          left: auto;
        }
      }
      &.disabled {
        i {
          color: rgba(136, 136, 136, 0.56);
          background: #eeeeee;
          border-color: transparent;
          opacity: 1 !important;
        }
      }
      @media (min-width: 1681px) {
        margin-right: 20px;
        i {
          width: 48px;
          height: 48px;
          line-height: 45px;
          font-size: 1.5rem;
        }
      }
    }
  }
}
.locationNearStore {
  padding: 15px 15px;
  @media (max-width: 599px) {
    padding-bottom: 0;
  }
  h4 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    color: $tertiary;
    @media (min-width: 1681px) {
      font-size: 20px;
    }
    .storeSort {
      display: inline-block;
      width: 150px;
      line-height: normal;
      position: relative;
      padding-left: 40px;
      & > span {
        position: absolute;
        left: 0;
        top: 10px;
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1024px) {
    padding: 15px 35px;
  }
}
.locationList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  .q-item {
    width: 100%;
    padding: 15px;
    /*@media (min-width: 860px) {
      flex: 10000 1 50%;
      width: 50%;
    }
    @media (min-width: 1366px) {
      flex: 10000 1 33.33%;
      width: 33.33%;
    }*/

    .locationListInner {
      position: relative;
      flex-direction: row;
      padding: 15px;
      background: #fafafa;
      border: 1px solid #cccccc;
      border-radius: 5px;
      @media (min-width: 860px) {
        padding: 30px;
      }
      .locationListTitle {
        padding-bottom: 15px;
        font-size: 20px;
        font-weight: bold;
        color: #0f141a;
        @media (max-width: 850px) {
          padding-right: 45px;
          font-size: 18px;
        }
        @media (min-width: 1681px) {
          font-size: 24px;
        }
        span {
          margin: 0 10px;
          padding: 5px 15px;
          font-size: 14px;
          line-height: normal;
          font-weight: bold;
          display: inline-block;
          vertical-align: middle;
          color: #008a03;
          border: 1px solid currentColor;
          border-radius: 15px;
        }
      }
      .text-caption {
        padding-bottom: 15px;
        font-size: 14px;
        color: #0f141a;
        @media (min-width: 1681px) {
          font-size: 16px;
        }
      }
      .q-btn--flat {
        font-size: 16px;
      }
      .q-item__section--side {
        font-size: 16px;
        color: #0f141a;
        @media (min-width: 1366px) {
          font-size: 20px;
        }
        @media (min-width: 1681px) {
          font-size: 24px;
        }
        @media (max-width: 850px) {
          position: absolute;
          top: 20px;
          right: 10px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
