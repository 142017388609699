import { render, staticRenderFns } from "./Location.vue?vue&type=template&id=2657e1d8"
import script from "./Location.vue?vue&type=script&lang=js"
export * from "./Location.vue?vue&type=script&lang=js"
import style0 from "./Location.vue?vue&type=style&index=0&id=2657e1d8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QNoSsr from 'quasar/src/components/no-ssr/QNoSsr.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QSkeleton,QBreadcrumbs,QBreadcrumbsEl,QBtn,QNoSsr,QImg,QTooltip,QSelect,QInfiniteScroll,QColor,QItem});
